var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Berat Badan/Weight (kg) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Berat Badan/Weight (kg)",
      "height": "62px"
    },
    model: {
      value: _vm.value.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "weight", $$v);
      },
      expression: "value.weight"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Tinggi Badan/Height (cm) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Tinggi Badan/Height (cm)",
      "height": "62px"
    },
    model: {
      value: _vm.value.height,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "height", $$v);
      },
      expression: "value.height"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" IMT/BMI (kg/m2): ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan IMT/BMI (kg/m2)",
      "height": "62px"
    },
    model: {
      value: _vm.value.bodyMassIndex,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "bodyMassIndex", $$v);
      },
      expression: "value.bodyMassIndex"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Status Gizi ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Status Gizi",
      "height": "62px"
    },
    model: {
      value: _vm.value.nutritionalStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "nutritionalStatus", $$v);
      },
      expression: "value.nutritionalStatus"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Berat Badan Ideal (Batas Bawah) / Minimum Ideal Body Weight ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Berat Badan Ideal (Batas Bawah) / Minimum Ideal Body Weight",
      "height": "62px"
    },
    model: {
      value: _vm.value.minIdealWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "minIdealWeight", $$v);
      },
      expression: "value.minIdealWeight"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Berat Badan Ideal (Batas Atas) / Maximum Ideal Body Weight ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Berat Badan Ideal (Batas Atas) / Maximum Ideal Body Weight",
      "height": "62px"
    },
    model: {
      value: _vm.value.maxIdealWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "maxIdealWeight", $$v);
      },
      expression: "value.maxIdealWeight"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kebutuhan Energi Bassal (kkal) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Kebutuhan Energi Bassal (kkal)",
      "height": "62px"
    },
    model: {
      value: _vm.value.basalEnergyNeeds,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "basalEnergyNeeds", $$v);
      },
      expression: "value.basalEnergyNeeds"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kondisi Keibuan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Kondisi Keibuan",
      "height": "62px"
    },
    model: {
      value: _vm.value.maternalCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "maternalCondition", $$v);
      },
      expression: "value.maternalCondition"
    }
  }, [_c('option', {
    attrs: {
      "value": "Tidak ada"
    }
  }, [_vm._v(" Tidak ada ")]), _c('option', {
    attrs: {
      "value": "Hamil Trimester 1"
    }
  }, [_vm._v(" Hamil Trimester 1 ")]), _c('option', {
    attrs: {
      "value": "Hamil Trimester 2"
    }
  }, [_vm._v(" Hamil Trimester 2 ")]), _c('option', {
    attrs: {
      "value": "Hamil Trimester 3"
    }
  }, [_vm._v(" Hamil Trimester 3 ")]), _c('option', {
    attrs: {
      "value": "Menyusui Bayi 0-6 Bulan"
    }
  }, [_vm._v(" Menyusui Bayi 0-6 Bulan ")]), _c('option', {
    attrs: {
      "value": "Menyusui Bayi 7-12 Bulan"
    }
  }, [_vm._v(" Menyusui Bayi 7-12 Bulan ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Tambahan kalori kondisi keibuan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Tambahan kalori kondisi keibuan",
      "height": "62px"
    },
    model: {
      value: _vm.value.maternalCalorieAddition,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "maternalCalorieAddition", $$v);
      },
      expression: "value.maternalCalorieAddition"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Pertahankan Hal Ini / What to Maintain ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Pertahankan Hal Ini / What to Maintain",
      "height": "62px"
    },
    model: {
      value: _vm.value.maintain,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "maintain", $$v);
      },
      expression: "value.maintain"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Perbaiki Hal InI / What to Improve ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Perbaiki Hal InI / What to Improve",
      "height": "62px"
    },
    model: {
      value: _vm.value.improve,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "improve", $$v);
      },
      expression: "value.improve"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }