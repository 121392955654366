var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Jenis olahraga & durasi/Exercise type & duration ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value.excercise,
      expression: "value.excercise"
    }, {
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '18px',
        width: '100%',
        borderRadius: '6px',
        height: '180px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n        border: '1px solid #C4C4C4',\n        padding: '18px',\n        width: '100%',\n        borderRadius: '6px',\n        height: '180px',\n        '&:focus, &:focus-visible': {\n          outline: 'unset',\n        },\n      }"
    }],
    attrs: {
      "rows": "8",
      "placeholder": "Masukkan Jenis olahraga & durasi/Exercise type & duration"
    },
    domProps: {
      "value": _vm.value.excercise
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.value, "excercise", $event.target.value);
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Tipikal keaktifan pada hari kerja (weekdays) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Tipikal keaktifan pada hari kerja (weekdays)",
      "height": "62px"
    },
    model: {
      value: _vm.value.weekdaysTypicalActivity,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "weekdaysTypicalActivity", $$v);
      },
      expression: "value.weekdaysTypicalActivity"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Tipikal keaktifan pada hari libur dan akhir pekan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Tipikal keaktifan pada hari libur dan akhir pekan",
      "height": "62px"
    },
    model: {
      value: _vm.value.weekendTypicalActivity,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "weekendTypicalActivity", $$v);
      },
      expression: "value.weekendTypicalActivity"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Presentase Aktivitas Fisik ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Presentase Aktivitas Fisik",
      "height": "62px"
    },
    model: {
      value: _vm.value.activityPercentage,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "activityPercentage", $$v);
      },
      expression: "value.activityPercentage"
    }
  }, [_c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(" 0% ")]), _c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10% ")]), _c('option', {
    attrs: {
      "value": "30"
    }
  }, [_vm._v(" 30% ")]), _c('option', {
    attrs: {
      "value": "40"
    }
  }, [_vm._v(" 40% ")]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v(" 50% ")]), _c('option', {
    attrs: {
      "value": "60"
    }
  }, [_vm._v(" 60% ")]), _c('option', {
    attrs: {
      "value": "70"
    }
  }, [_vm._v(" 70% ")]), _c('option', {
    attrs: {
      "value": "80"
    }
  }, [_vm._v(" 80% ")]), _c('option', {
    attrs: {
      "value": "90"
    }
  }, [_vm._v(" 90% ")])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }