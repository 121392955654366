<template>
  <c-box>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Jenis olahraga & durasi/Exercise type & duration
      </c-form-label>
      <textarea
        v-model="value.excercise"
        v-chakra="{
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset',
          },
        }"
        rows="8"
        placeholder="Masukkan Jenis olahraga & durasi/Exercise type & duration"
      />
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Tipikal keaktifan pada hari kerja (weekdays)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.weekdaysTypicalActivity"
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Tipikal keaktifan pada hari kerja (weekdays)"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Tipikal keaktifan pada hari libur dan akhir pekan
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.weekendTypicalActivity"
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Tipikal keaktifan pada hari libur dan akhir pekan"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Presentase Aktivitas Fisik
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.activityPercentage"
          placeholder="Masukan Presentase Aktivitas Fisik"
          height="62px"
        >
          <option value="0">
            0%
          </option>
          <option value="10">
            10%
          </option>
          <option value="30">
            30%
          </option>
          <option value="40">
            40%
          </option>
          <option value="50">
            50%
          </option>
          <option value="60">
            60%
          </option>
          <option value="70">
            70%
          </option>
          <option value="80">
            80%
          </option>
          <option value="90">
            90%
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: 'PhysicalActivityHistoryForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>