<template>
  <c-box>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nilai Pengukuran Lingkar Pinggang (cm)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.waistSize"
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nilai Pengukuran Lingkar Pinggang (cm)"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Standar Lingkar Pinggang
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.waistSizeStandard"
          placeholder="Masukan Standar Lingkar Pinggang"
          height="62px"
        >
          <option value="Laki-Laki">
            Laki-Laki
          </option>
          <option value="Perempuan">
            Perempuan
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan Lingkar Pinggang
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.waistSizeConclusion"
          placeholder="Masukan Kesimpulan Lingkar Pinggang"
          height="62px"
        >
          <option value="Normal">
            Normal
          </option>
          <option value="Berlebih">
            Berlebih
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nilai Pengukuran % Lemak Tubuh
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.fat"
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nilai Pengukuran % Lemak Tubuh"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Standar % Lemak Tubuh
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.fatStandard"
          placeholder="Masukan Standar % Lemak Tubuh"
          height="62px"
        >
          <option value="Laki-Laki">
            Laki-Laki
          </option>
          <option value="Perempuan">
            Perempuan
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan % Lemak Tubuh
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.fatConclusion"
          placeholder="Masukan Kesimpulan % Lemak Tubuh"
          height="62px"
        >
          <option value="Rendah">
            Rendah
          </option>
          <option value="Normal">
            Normal
          </option>
          <option value="Tinggi">
            Tinggi
          </option>
          <option value="Sangat Tinggi">
            Sangat Tinggi
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nilai Pengukuran Visceral Fat
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.visceralFat"
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nilai Pengukuran Visceral Fat"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Standar Visceral Fat
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.visceralFatStandard"
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Standar Visceral Fat"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan Visceral Fat
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.visceralFatConclusion"
          placeholder="Masukan Kesimpulan Visceral Fat"
          height="62px"
        >
          <option value="Normal">
            Normal
          </option>
          <option value="Berlebih">
            Berlebih
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nilai Pengukuran Massa Otot
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.muscleMass"
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nilai Pengukuran % Lemak Tubuh"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Standar Massa Otot
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.muscleMassStandard"
          placeholder="Masukan Standar Massa Otot"
          height="62px"
        >
          <option value="Laki-laki">
            Laki-laki
          </option>
          <option value="Perempuan">
            Perempuan
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan Massa Otot
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.muscleMassConclusion"
          placeholder="Masukan Kesimpulan Massa Otot"
          height="62px"
        >
          <option value="Rendah">
            Rendah
          </option>
          <option value="Normal">
            Normal
          </option>
          <option value="Tinggi">
            Tinggi
          </option>
          <option value="Sangat Tinggi">
            Sangat Tinggi
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: 'BodyCompositionMeasureForm',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
}
</script>