var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nilai Pengukuran Gula Darah Sewaktu ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nilai Pengukuran Gula Darah Sewaktu",
      "height": "62px"
    },
    model: {
      value: _vm.value.bloodSugar,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "bloodSugar", $$v);
      },
      expression: "value.bloodSugar"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Standar Gula Darah Sewaktu ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Standar Gula Darah Sewaktu",
      "height": "62px"
    },
    model: {
      value: _vm.value.bloodSugarStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "bloodSugarStandard", $$v);
      },
      expression: "value.bloodSugarStandard"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan Gula Darah Sewaktu ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Kesimpulan Gula Darah Sewaktu",
      "height": "62px"
    },
    model: {
      value: _vm.value.bloodSugarConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "bloodSugarConclusion", $$v);
      },
      expression: "value.bloodSugarConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "Rendah"
    }
  }, [_vm._v(" Rendah ")]), _c('option', {
    attrs: {
      "value": "Normal"
    }
  }, [_vm._v(" Normal ")]), _c('option', {
    attrs: {
      "value": "Tinggi"
    }
  }, [_vm._v(" Tinggi ")]), _c('option', {
    attrs: {
      "value": "Sangat Tinggi"
    }
  }, [_vm._v(" Sangat Tinggi ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nilai Pengukuran Total Kolesterol ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nilai Pengukuran Total Kolesterol",
      "height": "62px"
    },
    model: {
      value: _vm.value.colestrol,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "colestrol", $$v);
      },
      expression: "value.colestrol"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Standar Total Kolesterol ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Standar Total Kolesterol",
      "height": "62px"
    },
    model: {
      value: _vm.value.colestrolStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "colestrolStandard", $$v);
      },
      expression: "value.colestrolStandard"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nilai Pengukuran Kolesterol HDL ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nilai Pengukuran Kolesterol HDL",
      "height": "62px"
    },
    model: {
      value: _vm.value.colestrolHDL,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "colestrolHDL", $$v);
      },
      expression: "value.colestrolHDL"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan Total Kolesterol ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Kesimpulan Total Kolesterol",
      "height": "62px"
    },
    model: {
      value: _vm.value.colestrolConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "colestrolConclusion", $$v);
      },
      expression: "value.colestrolConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "Rendah"
    }
  }, [_vm._v(" Rendah ")]), _c('option', {
    attrs: {
      "value": "Normal"
    }
  }, [_vm._v(" Normal ")]), _c('option', {
    attrs: {
      "value": "Tinggi"
    }
  }, [_vm._v(" Tinggi ")]), _c('option', {
    attrs: {
      "value": "Sangat Tinggi"
    }
  }, [_vm._v(" Sangat Tinggi ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Standar Kolesterol HDL ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Standar Kolesterol HDL",
      "height": "62px"
    },
    model: {
      value: _vm.value.colestrolHDLStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "colestrolHDLStandard", $$v);
      },
      expression: "value.colestrolHDLStandard"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan Kolesterol HDL ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Kesimpulan Kolesterol HDL",
      "height": "62px"
    },
    model: {
      value: _vm.value.colestrolHDLConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "colestrolHDLConclusion", $$v);
      },
      expression: "value.colestrolHDLConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "Rendah"
    }
  }, [_vm._v(" Rendah ")]), _c('option', {
    attrs: {
      "value": "Normal"
    }
  }, [_vm._v(" Normal ")]), _c('option', {
    attrs: {
      "value": "Tinggi"
    }
  }, [_vm._v(" Tinggi ")]), _c('option', {
    attrs: {
      "value": "Sangat Tinggi"
    }
  }, [_vm._v(" Sangat Tinggi ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nilai Pengukuran Kolesterol LDL ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nilai Pengukuran Kolesterol LDL",
      "height": "62px"
    },
    model: {
      value: _vm.value.colestrolLDL,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "colestrolLDL", $$v);
      },
      expression: "value.colestrolLDL"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Standar Kolesterol LDL ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Standar Kolesterol LDL",
      "height": "62px"
    },
    model: {
      value: _vm.value.colestrolLDLStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "colestrolLDLStandard", $$v);
      },
      expression: "value.colestrolLDLStandard"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan Kolesterol LDL ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Kesimpulan Kolesterol LDL",
      "height": "62px"
    },
    model: {
      value: _vm.value.colestrolLDLConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "colestrolLDLConclusion", $$v);
      },
      expression: "value.colestrolLDLConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "Rendah"
    }
  }, [_vm._v(" Rendah ")]), _c('option', {
    attrs: {
      "value": "Normal"
    }
  }, [_vm._v(" Normal ")]), _c('option', {
    attrs: {
      "value": "Tinggi"
    }
  }, [_vm._v(" Tinggi ")]), _c('option', {
    attrs: {
      "value": "Sangat Tinggi"
    }
  }, [_vm._v(" Sangat Tinggi ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nilai Pengukuran Asam Urat ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nilai Pengukuran Asam Urat",
      "height": "62px"
    },
    model: {
      value: _vm.value.uricAcid,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "uricAcid", $$v);
      },
      expression: "value.uricAcid"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Standar Asam Urat ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Standar Asam Urat",
      "height": "62px"
    },
    model: {
      value: _vm.value.uricAcidStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "uricAcidStandard", $$v);
      },
      expression: "value.uricAcidStandard"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan Asam Urat ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Kesimpulan Asam Urat",
      "height": "62px"
    },
    model: {
      value: _vm.value.uricAcidConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "uricAcidConclusion", $$v);
      },
      expression: "value.uricAcidConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "Rendah"
    }
  }, [_vm._v(" Rendah ")]), _c('option', {
    attrs: {
      "value": "Normal"
    }
  }, [_vm._v(" Normal ")]), _c('option', {
    attrs: {
      "value": "Tinggi"
    }
  }, [_vm._v(" Tinggi ")]), _c('option', {
    attrs: {
      "value": "Sangat Tinggi"
    }
  }, [_vm._v(" Sangat Tinggi ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nutrition-Focused Physical Findings ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value.nutritionFocusedPhysicalFinding,
      expression: "value.nutritionFocusedPhysicalFinding"
    }, {
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '18px',
        width: '100%',
        borderRadius: '6px',
        height: '180px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n        border: '1px solid #C4C4C4',\n        padding: '18px',\n        width: '100%',\n        borderRadius: '6px',\n        height: '180px',\n        '&:focus, &:focus-visible': {\n          outline: 'unset',\n        },\n      }"
    }],
    attrs: {
      "rows": "8",
      "placeholder": "Masukkan Nutrition-Focused Physical Findings"
    },
    domProps: {
      "value": _vm.value.nutritionFocusedPhysicalFinding
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.value, "nutritionFocusedPhysicalFinding", $event.target.value);
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Client History ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value.clientHistory,
      expression: "value.clientHistory"
    }, {
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '18px',
        width: '100%',
        borderRadius: '6px',
        height: '180px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n        border: '1px solid #C4C4C4',\n        padding: '18px',\n        width: '100%',\n        borderRadius: '6px',\n        height: '180px',\n        '&:focus, &:focus-visible': {\n          outline: 'unset',\n        },\n      }"
    }],
    attrs: {
      "rows": "8",
      "placeholder": "Masukkan Client History"
    },
    domProps: {
      "value": _vm.value.clientHistory
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.value, "clientHistory", $event.target.value);
      }
    }
  })], 1), _vm._l(_vm.value.others, function (item, index) {
    return _c('c-flex', {
      key: 'others' + index,
      attrs: {
        "gap": "6",
        "mt": "33px",
        "mb": "18px"
      }
    }, [_c('c-form-control', {
      attrs: {
        "flex-grow": "1"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Nama Field ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Nama Field",
        "height": "62px"
      },
      model: {
        value: item.field,
        callback: function callback($$v) {
          _vm.$set(item, "field", $$v);
        },
        expression: "item.field"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "flex-grow": "1"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Value Field ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Value Field",
        "height": "62px"
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1)], 1), _c('c-button', {
      attrs: {
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link"
      },
      on: {
        "click": function click($event) {
          return _vm.value.others.splice(index, 1);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty"
      }
    })], 1)], 1);
  }), _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "left-icon": "add",
      "mb": "25px"
    },
    on: {
      "click": _vm.addOther
    }
  }, [_vm._v(" Tambah Lainnya ")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }