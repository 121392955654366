var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm._l(_vm.steps, function (step, index) {
    return [_vm.stepIndex === index && !_vm.isSuccess ? _c('CardStep', {
      key: index,
      attrs: {
        "current": index + 1,
        "total": _vm.steps.length,
        "is-prev": index !== 0,
        "is-next": true
      },
      on: {
        "prev": function prev($event) {
          _vm.stepIndex--;
        },
        "next": _vm.nextForm
      }
    }, [_c('c-text', {
      attrs: {
        "font-weight": "700",
        "mb": "42px"
      }
    }, [_vm._v(" " + _vm._s(step.title) + " ")]), step.type === 'profile' ? _c('ProfileForm', {
      model: {
        value: _vm.profile,
        callback: function callback($$v) {
          _vm.profile = $$v;
        },
        expression: "profile"
      }
    }) : _vm._e(), step.type === 'nutritionAssessment' ? _c('NutritionAssessment', {
      attrs: {
        "age": _vm.profile.age,
        "gender": _vm.gender ? _vm.gender : 'female'
      },
      model: {
        value: _vm.nutritionAssessment,
        callback: function callback($$v) {
          _vm.nutritionAssessment = $$v;
        },
        expression: "nutritionAssessment"
      }
    }) : _vm._e(), step.type === 'physicalActivityHistory' ? _c('PhysicalActivityHistory', {
      model: {
        value: _vm.physicalActivityHistory,
        callback: function callback($$v) {
          _vm.physicalActivityHistory = $$v;
        },
        expression: "physicalActivityHistory"
      }
    }) : _vm._e(), step.type === 'calorieMacroNutrientIntake' ? _c('CalorieMacroNutrientIntake', {
      attrs: {
        "energy-bassal": _vm.nutritionAssessment.basalEnergyNeeds,
        "activity-percentage": _vm.physicalActivityHistory.activityPercentage,
        "addition-maternal-calorie": _vm.nutritionAssessment.maternalCalorieAddition,
        "weight": _vm.nutritionAssessment.weight,
        "calorie-deficit": _vm.calorieDeficit
      },
      model: {
        value: _vm.calorieMacroNutrientIntake,
        callback: function callback($$v) {
          _vm.calorieMacroNutrientIntake = $$v;
        },
        expression: "calorieMacroNutrientIntake"
      }
    }) : _vm._e(), step.type === 'foodNutritionHistory' ? _c('FoodNutritionHistory', {
      model: {
        value: _vm.foodNutritionHistory,
        callback: function callback($$v) {
          _vm.foodNutritionHistory = $$v;
        },
        expression: "foodNutritionHistory"
      }
    }) : _vm._e(), step.type === 'bodyCompositionMeasure' ? _c('BodyCompositionMeasure', {
      model: {
        value: _vm.bodyCompositionMeasure,
        callback: function callback($$v) {
          _vm.bodyCompositionMeasure = $$v;
        },
        expression: "bodyCompositionMeasure"
      }
    }) : _vm._e(), step.type === 'biochemicalData' ? _c('BiochemicalData', {
      model: {
        value: _vm.biochemicalData,
        callback: function callback($$v) {
          _vm.biochemicalData = $$v;
        },
        expression: "biochemicalData"
      }
    }) : _vm._e(), step.type === 'primaryNutritionDiagnosis' ? _c('PrimaryNutritionDiagnosis', {
      model: {
        value: _vm.primaryNutritionDiagnosis,
        callback: function callback($$v) {
          _vm.primaryNutritionDiagnosis = $$v;
        },
        expression: "primaryNutritionDiagnosis"
      }
    }) : _vm._e(), step.type === 'nutritionIntervention' ? _c('NutritionIntervention', {
      model: {
        value: _vm.nutritionIntervention,
        callback: function callback($$v) {
          _vm.nutritionIntervention = $$v;
        },
        expression: "nutritionIntervention"
      }
    }) : _vm._e(), step.type === 'nutritionMonitoring' ? _c('NutritionMonitoring', {
      model: {
        value: _vm.nutritionMonitoring,
        callback: function callback($$v) {
          _vm.nutritionMonitoring = $$v;
        },
        expression: "nutritionMonitoring"
      }
    }) : _vm._e()], 1) : _vm._e()];
  }), _vm.isSuccess ? _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "align": "center"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h2",
      "font-size": "36px",
      "font-weight": "600",
      "color": "black.900",
      "margin-bottom": "54px",
      "text-align": "center",
      "line-height": "54px"
    }
  }, [_vm._v(" Submit Berhasil! ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/paid.png'),
      "mx": "auto",
      "margin-bottom": "30px"
    }
  }), _c('c-text', {
    attrs: {
      "margin-bottom": "20px",
      "color": "gray.900",
      "font-size": ['14px', '24px'],
      "line-height": ['21px', '36px'],
      "font-family": "Roboto"
    },
    domProps: {
      "textContent": _vm._s('Adime Note kamu berhasil di-submit')
    }
  }), _c('c-button', {
    attrs: {
      "as": "router-link",
      "to": {
        name: 'nutri.adime-note'
      },
      "font-weight": "700",
      "right-icon": "arrow-forward",
      "color": "primary.400",
      "variant": "outline",
      "border-color": "primary.400",
      "border-radius": "200px",
      "py": "10px",
      "px": "30px"
    }
  }, [_vm._v(" Kembali ke ADIME Note ")])], 1) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }