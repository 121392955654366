var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm.profileGizi ? _c('EnergiForm', {
    attrs: {
      "value": _vm.tValue,
      "aktivitas-fisik": _vm.profileGizi.physicalActivity,
      "defisit-kalori": _vm.profileGizi.calorieDeficit,
      "kebutuhan-energi": _vm.profileGizi.basalEnergyNeeds,
      "tambahan-kalori": _vm.profileGizi.extraMaternalCalories,
      "without-adds": ""
    },
    on: {
      "input": function input($event) {
        return _vm.changeTValue($event);
      }
    }
  }) : _vm._e(), _vm.profileGizi ? _c('ProteinForm', {
    attrs: {
      "value": _vm.tValue,
      "weight": _vm.profileGizi.weight,
      "kebutuhan-asupan-energi": _vm.value.energyNeeds,
      "without-adds": ""
    },
    on: {
      "input": function input($event) {
        return _vm.changeTValue($event);
      }
    }
  }) : _vm._e(), _vm.profileGizi ? _c('LemakForm', {
    attrs: {
      "value": _vm.tValue,
      "kebutuhan-asupan-energi": _vm.value.energyNeeds,
      "without-adds": ""
    },
    on: {
      "input": function input($event) {
        return _vm.changeTValue($event);
      }
    }
  }) : _vm._e(), _vm.profileGizi ? _c('KarbohidratForm', {
    attrs: {
      "value": _vm.tValue,
      "kebutuhan-asupan-energi": +_vm.value.energyNeeds,
      "kebutuhan-lemak": +_vm.value.fatNeedsConstant,
      "kebutuhan-protein": +_vm.value.proteinNeedsConstant,
      "without-adds": ""
    },
    on: {
      "input": function input($event) {
        return _vm.changeTValue($event);
      }
    }
  }) : _vm._e(), _vm.profileGizi ? _c('SeratForm', {
    attrs: {
      "value": _vm.tValue,
      "without-adds": ""
    },
    on: {
      "input": function input($event) {
        return _vm.changeTValue($event);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }