var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-checkbox-group', {
    attrs: {
      "variant-color": "green",
      "default-value": _vm.value.checkParts
    },
    model: {
      value: _vm.value.checkParts,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "checkParts", $$v);
      },
      expression: "value.checkParts"
    }
  }, _vm._l(_vm.checkParts, function (item, index) {
    return _c('c-checkbox', {
      key: index,
      attrs: {
        "w": "100%",
        "mb": "16px",
        "font-size": "18px",
        "line-height": "27px",
        "variant-color": "primary",
        "value": item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-weigh": "400",
      "font-family": "Roboto",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px",
      "color": "primary.400"
    }
  }, [_vm._v(" Tuliskan indikator spesifik yang akan dimonitor & kriterianya ")])], 1), _c('table', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "100%"
    }
  }, [_c('thead', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "bg": "rgba(0, 140, 129, 0.1);",
      "color": "primary.400",
      "text-align": "left",
      "text-transform": "uppercase",
      "font-weight": "700",
      "font-size": "12px"
    }
  }, [_c('tr', [_c('th', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "px": "20px",
      "py": "7px",
      "border-top-left-radius": "12px",
      "border-bottom": "1px solid #008C81"
    }
  }, [_vm._v(" Jenis Indikator ")]), _c('th', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "px": "20px",
      "py": "7px",
      "border": "1px solid #008C81",
      "border-top": "0"
    }
  }, [_vm._v(" Kriteria Indikator ")]), _c('th', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "px": "20px",
      "py": "7px",
      "border-top-right-radius": "12px",
      "border-bottom": "1px solid #008C81"
    }
  })])]), _c('tbody', _vm._l(_vm.value.indicators, function (item, index) {
    return _c('tr', {
      key: 'indicators' + index
    }, [_c('td', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "border": "1px solid #C4C4C4"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "border": "unset",
        "placeholder": "Masukkan Jenis Indikator",
        "height": "62px"
      },
      model: {
        value: item.type,
        callback: function callback($$v) {
          _vm.$set(item, "type", $$v);
        },
        expression: "item.type"
      }
    })], 1), _c('td', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "border": "1px solid #C4C4C4"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "border": "unset",
        "placeholder": "Masukkan Kriteria Indikator",
        "height": "62px"
      },
      model: {
        value: item.criteria,
        callback: function callback($$v) {
          _vm.$set(item, "criteria", $$v);
        },
        expression: "item.criteria"
      }
    })], 1), _c('td', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "border": "1px solid #C4C4C4",
        "align": "center"
      }
    }, [_c('c-button', {
      attrs: {
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link"
      },
      on: {
        "click": function click($event) {
          return _vm.value.indicators.splice(index, 1);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty"
      }
    })], 1)], 1)]);
  }), 0)]), _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "0 0 12px 12px",
      "h": "62px",
      "w": "100%",
      "left-icon": "add",
      "mb": "25px"
    },
    on: {
      "click": _vm.addNutritionMonitoringIndicator
    }
  }, [_vm._v(" Tambah Indikator ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }