var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Preskripsi Diet/Nutrition Prescription ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Preskripsi Diet/Nutrition Prescription",
      "height": "62px"
    },
    model: {
      value: _vm.value.nutritionPrescription,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "nutritionPrescription", $$v);
      },
      expression: "value.nutritionPrescription"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Target Pencapaian/Goals (SMART) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Target Pencapaian/Goals (SMART)",
      "height": "62px"
    },
    model: {
      value: _vm.value.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "goal", $$v);
      },
      expression: "value.goal"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }