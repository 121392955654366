var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Preferensi makanan/Food preference ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Preferensi makanan/Food preference",
      "height": "62px"
    },
    model: {
      value: _vm.value.foodPreference,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "foodPreference", $$v);
      },
      expression: "value.foodPreference"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Alergi makanan/Food allergies ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Alergi makanan/Food allergies",
      "height": "62px"
    },
    model: {
      value: _vm.value.foodAllergies,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "foodAllergies", $$v);
      },
      expression: "value.foodAllergies"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Konsumsi cairan/hari (Termasuk minuman selain air mineral) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Konsumsi cairan/hari (Termasuk minuman selain air mineral)",
      "height": "62px"
    },
    model: {
      value: _vm.value.fluidConsumption,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "fluidConsumption", $$v);
      },
      expression: "value.fluidConsumption"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Penyedia makanan/Food preparation provider ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Penyedia makanan/Food preparation provider",
      "height": "62px"
    },
    model: {
      value: _vm.value.personServeFood,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "personServeFood", $$v);
      },
      expression: "value.personServeFood"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Catatan tambahan/Additional notes ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value.additionalNote,
      expression: "value.additionalNote"
    }, {
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '18px',
        width: '100%',
        borderRadius: '6px',
        height: '180px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n        border: '1px solid #C4C4C4',\n        padding: '18px',\n        width: '100%',\n        borderRadius: '6px',\n        height: '180px',\n        '&:focus, &:focus-visible': {\n          outline: 'unset',\n        },\n      }"
    }],
    attrs: {
      "rows": "8",
      "placeholder": "Masukkan Catatan tambahan/Additional notes"
    },
    domProps: {
      "value": _vm.value.additionalNote
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.value, "additionalNote", $event.target.value);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }