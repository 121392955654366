var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Problem ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Problem",
      "height": "62px"
    },
    model: {
      value: _vm.value.problem,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "problem", $$v);
      },
      expression: "value.problem"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Etiology ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Etiology",
      "height": "62px"
    },
    model: {
      value: _vm.value.etiology,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "etiology", $$v);
      },
      expression: "value.etiology"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Sign & Symptoms ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Sign & Symptoms",
      "height": "62px"
    },
    model: {
      value: _vm.value.signSymptom,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "signSymptom", $$v);
      },
      expression: "value.signSymptom"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }