<template>
  <c-box>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Preskripsi Diet/Nutrition Prescription
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.nutritionPrescription"
          type="text"
          error-border-color="red.300"
          placeholder="Masukkan Preskripsi Diet/Nutrition Prescription"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Target Pencapaian/Goals (SMART)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.goal"
          type="text"
          error-border-color="red.300"
          placeholder="Masukkan Target Pencapaian/Goals (SMART)"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: 'NutritionInterventionForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>