<template>
  <c-box>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nilai Pengukuran Gula Darah Sewaktu
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.bloodSugar"
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nilai Pengukuran Gula Darah Sewaktu"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Standar Gula Darah Sewaktu
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.bloodSugarStandard"
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Standar Gula Darah Sewaktu"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan Gula Darah Sewaktu
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.bloodSugarConclusion"
          placeholder="Masukan Kesimpulan Gula Darah Sewaktu"
          height="62px"
        >
          <option value="Rendah">
            Rendah
          </option>
          <option value="Normal">
            Normal
          </option>
          <option value="Tinggi">
            Tinggi
          </option>
          <option value="Sangat Tinggi">
            Sangat Tinggi
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nilai Pengukuran Total Kolesterol
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.colestrol"
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nilai Pengukuran Total Kolesterol"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Standar Total Kolesterol
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.colestrolStandard"
          type="text"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Standar Total Kolesterol"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nilai Pengukuran Kolesterol HDL
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.colestrolHDL"
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nilai Pengukuran Kolesterol HDL"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan Total Kolesterol
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.colestrolConclusion"
          placeholder="Masukan Kesimpulan Total Kolesterol"
          height="62px"
        >
          <option value="Rendah">
            Rendah
          </option>
          <option value="Normal">
            Normal
          </option>
          <option value="Tinggi">
            Tinggi
          </option>
          <option value="Sangat Tinggi">
            Sangat Tinggi
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Standar Kolesterol HDL
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.colestrolHDLStandard"
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Standar Kolesterol HDL"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan Kolesterol HDL
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.colestrolHDLConclusion"
          placeholder="Masukan Kesimpulan Kolesterol HDL"
          height="62px"
        >
          <option value="Rendah">
            Rendah
          </option>
          <option value="Normal">
            Normal
          </option>
          <option value="Tinggi">
            Tinggi
          </option>
          <option value="Sangat Tinggi">
            Sangat Tinggi
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nilai Pengukuran Kolesterol LDL
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.colestrolLDL"
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nilai Pengukuran Kolesterol LDL"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Standar Kolesterol LDL
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.colestrolLDLStandard"
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Standar Kolesterol LDL"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan Kolesterol LDL
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.colestrolLDLConclusion"
          placeholder="Masukan Kesimpulan Kolesterol LDL"
          height="62px"
        >
          <option value="Rendah">
            Rendah
          </option>
          <option value="Normal">
            Normal
          </option>
          <option value="Tinggi">
            Tinggi
          </option>
          <option value="Sangat Tinggi">
            Sangat Tinggi
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nilai Pengukuran Asam Urat
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.uricAcid"
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Nilai Pengukuran Asam Urat"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Standar Asam Urat
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.uricAcidStandard"
          type="number"
          min="0"
          error-border-color="red.300"
          placeholder="Masukkan Standar Asam Urat"
          height="62px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan Asam Urat
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.uricAcidConclusion"
          placeholder="Masukan Kesimpulan Asam Urat"
          height="62px"
        >
          <option value="Rendah">
            Rendah
          </option>
          <option value="Normal">
            Normal
          </option>
          <option value="Tinggi">
            Tinggi
          </option>
          <option value="Sangat Tinggi">
            Sangat Tinggi
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nutrition-Focused Physical Findings
      </c-form-label>
      <textarea
        v-model="value.nutritionFocusedPhysicalFinding"
        v-chakra="{
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset',
          },
        }"
        rows="8"
        placeholder="Masukkan Nutrition-Focused Physical Findings"
      />
    </c-form-control>
    <c-form-control mb="18px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Client History
      </c-form-label>
      <textarea
        v-model="value.clientHistory"
        v-chakra="{
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset',
          },
        }"
        rows="8"
        placeholder="Masukkan Client History"
      />
    </c-form-control>
    <c-flex
      v-for="(item, index) in value.others"
      :key="'others' + index"
      gap="6"
      mt="33px"
      mb="18px"
    >
      <c-form-control flex-grow="1">
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Nama Field
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="item.field"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Nama Field"
            height="62px"
          />
        </c-input-group>
      </c-form-control>
      <c-form-control flex-grow="1">
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Value Field
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="item.value"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Value Field"
            height="62px"
          />
        </c-input-group>
      </c-form-control>
      <c-button
        min-width="auto"
        variant-color="red"
        variant="link"
        @click="value.others.splice(index, 1)"
      >
        <c-image
          :src="require('@/assets/icon-trash-line.svg')"
          alt="empty"
        />
      </c-button>
    </c-flex>
    <c-button
      variant-color="primary"
      variant="outline"
      border-radius="60px"
      h="62px"
      w="100%"
      left-icon="add"
      mb="25px"
      @click="addOther"
    >
      Tambah Lainnya
    </c-button>
  </c-box>
</template>

<script>
export default {
  name: 'BiochemicalDataForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    addOther() {
      this.value.others.push({
        field: '',
        value: '',
      })
    },
  },
}
</script>