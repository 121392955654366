var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nilai Pengukuran Lingkar Pinggang (cm) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nilai Pengukuran Lingkar Pinggang (cm)",
      "height": "62px"
    },
    model: {
      value: _vm.value.waistSize,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "waistSize", $$v);
      },
      expression: "value.waistSize"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Standar Lingkar Pinggang ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Standar Lingkar Pinggang",
      "height": "62px"
    },
    model: {
      value: _vm.value.waistSizeStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "waistSizeStandard", $$v);
      },
      expression: "value.waistSizeStandard"
    }
  }, [_c('option', {
    attrs: {
      "value": "Laki-Laki"
    }
  }, [_vm._v(" Laki-Laki ")]), _c('option', {
    attrs: {
      "value": "Perempuan"
    }
  }, [_vm._v(" Perempuan ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan Lingkar Pinggang ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Kesimpulan Lingkar Pinggang",
      "height": "62px"
    },
    model: {
      value: _vm.value.waistSizeConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "waistSizeConclusion", $$v);
      },
      expression: "value.waistSizeConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "Normal"
    }
  }, [_vm._v(" Normal ")]), _c('option', {
    attrs: {
      "value": "Berlebih"
    }
  }, [_vm._v(" Berlebih ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nilai Pengukuran % Lemak Tubuh ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nilai Pengukuran % Lemak Tubuh",
      "height": "62px"
    },
    model: {
      value: _vm.value.fat,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "fat", $$v);
      },
      expression: "value.fat"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Standar % Lemak Tubuh ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Standar % Lemak Tubuh",
      "height": "62px"
    },
    model: {
      value: _vm.value.fatStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "fatStandard", $$v);
      },
      expression: "value.fatStandard"
    }
  }, [_c('option', {
    attrs: {
      "value": "Laki-Laki"
    }
  }, [_vm._v(" Laki-Laki ")]), _c('option', {
    attrs: {
      "value": "Perempuan"
    }
  }, [_vm._v(" Perempuan ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan % Lemak Tubuh ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Kesimpulan % Lemak Tubuh",
      "height": "62px"
    },
    model: {
      value: _vm.value.fatConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "fatConclusion", $$v);
      },
      expression: "value.fatConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "Rendah"
    }
  }, [_vm._v(" Rendah ")]), _c('option', {
    attrs: {
      "value": "Normal"
    }
  }, [_vm._v(" Normal ")]), _c('option', {
    attrs: {
      "value": "Tinggi"
    }
  }, [_vm._v(" Tinggi ")]), _c('option', {
    attrs: {
      "value": "Sangat Tinggi"
    }
  }, [_vm._v(" Sangat Tinggi ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nilai Pengukuran Visceral Fat ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nilai Pengukuran Visceral Fat",
      "height": "62px"
    },
    model: {
      value: _vm.value.visceralFat,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "visceralFat", $$v);
      },
      expression: "value.visceralFat"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Standar Visceral Fat ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Standar Visceral Fat",
      "height": "62px"
    },
    model: {
      value: _vm.value.visceralFatStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "visceralFatStandard", $$v);
      },
      expression: "value.visceralFatStandard"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan Visceral Fat ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Kesimpulan Visceral Fat",
      "height": "62px"
    },
    model: {
      value: _vm.value.visceralFatConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "visceralFatConclusion", $$v);
      },
      expression: "value.visceralFatConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "Normal"
    }
  }, [_vm._v(" Normal ")]), _c('option', {
    attrs: {
      "value": "Berlebih"
    }
  }, [_vm._v(" Berlebih ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nilai Pengukuran Massa Otot ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nilai Pengukuran % Lemak Tubuh",
      "height": "62px"
    },
    model: {
      value: _vm.value.muscleMass,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "muscleMass", $$v);
      },
      expression: "value.muscleMass"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Standar Massa Otot ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Standar Massa Otot",
      "height": "62px"
    },
    model: {
      value: _vm.value.muscleMassStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "muscleMassStandard", $$v);
      },
      expression: "value.muscleMassStandard"
    }
  }, [_c('option', {
    attrs: {
      "value": "Laki-laki"
    }
  }, [_vm._v(" Laki-laki ")]), _c('option', {
    attrs: {
      "value": "Perempuan"
    }
  }, [_vm._v(" Perempuan ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "18px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan Massa Otot ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukan Kesimpulan Massa Otot",
      "height": "62px"
    },
    model: {
      value: _vm.value.muscleMassConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "muscleMassConclusion", $$v);
      },
      expression: "value.muscleMassConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "Rendah"
    }
  }, [_vm._v(" Rendah ")]), _c('option', {
    attrs: {
      "value": "Normal"
    }
  }, [_vm._v(" Normal ")]), _c('option', {
    attrs: {
      "value": "Tinggi"
    }
  }, [_vm._v(" Tinggi ")]), _c('option', {
    attrs: {
      "value": "Sangat Tinggi"
    }
  }, [_vm._v(" Sangat Tinggi ")])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }