import { render, staticRenderFns } from "./nutrition-monitoring.vue?vue&type=template&id=421eab9e&"
import script from "./nutrition-monitoring.vue?vue&type=script&lang=js&"
export * from "./nutrition-monitoring.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CCheckbox: require('@chakra-ui/vue').CCheckbox, CCheckboxGroup: require('@chakra-ui/vue').CCheckboxGroup, CFormLabel: require('@chakra-ui/vue').CFormLabel, CFormControl: require('@chakra-ui/vue').CFormControl, CInput: require('@chakra-ui/vue').CInput, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox})
